import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { NAV_HEIGHTS } from '../header/types';
import SmallDoorVetLogo from '../../images/small-door-vet-logo.svg';
import Button from '../../ui/BaseButton';
import { saveParamsToCookie } from '../../utils/captureUrlParams';

const Container = styled.header`
  height: ${NAV_HEIGHTS.DESKTOP};
  padding: 0 70px;
  background: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: inset 0px -2px 0px ${COLORS.LIGHT_GRAY};
  transition: box-shadow 200ms ease;

  @media ${BREAKPOINTS.TABLET} {
    height: ${NAV_HEIGHTS.TABLET};
    padding: 0 40px;
  }

  @media (max-width: 650px) {
    padding: 0 20px;
    justify-content: center;
  }
`;

const Logo = styled.div`
  display: block;
  background: url(${SmallDoorVetLogo});
  background-repeat: no-repeat;
  width: 160px;
  height: 46px;
  flex-shrink: 0;

  @media ${BREAKPOINTS.TABLET} {
    background-size: 120px;
    width: 120px;
    height: 32px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    background-size: 110px;
    width: 110px;
    height: 29px;
  }
`;

interface CTAProps {
  cta: {
    href: string;
    text: string;
  };
}

const Header = ({ cta }: CTAProps) => {
  useEffect(() => {
    saveParamsToCookie();
  }, []);

  const CTA = () => (
    <Button
      type='external'
      href={cta.href}
      color={COLORS.NEW_ORANGE}
      css={css`
        padding: 14px 40px;
        font-weight: 600;
        text-transform: capitalize;
        flex-shrink: 0;
        @media ${BREAKPOINTS.TABLET} {
          padding: 13.5px 24px;
        }
        @media (max-width: 650px) {
          display: none;
        }
      `}
    >
      {cta.text}
    </Button>
  );
  return (
    <Container>
      <Logo />
      <CTA />
    </Container>
  );
};

export default Header;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { COLORS, BREAKPOINTS } from '../../ui/variables';
import Accordion from './Accordion';
import { MaxWidthWrapper } from '../../ui/utils';
import HeadingUppercase from '../../ui/HeadingUppercase';
import renderRichBodyText from '../../utils/renderRichText';
import ButtonCTA from './ButtonCTA';

const Container = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 140px 100px;
  margin-bottom: 20px;
  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

interface Props {
  button: string;
  href: string;
}

const Benefits = ({ button, href }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFaqGroup(groupName: { eq: "Benefits Founding Member" }) {
        FAQItems {
          ... on ContentfulAccordionItem {
            id
            question
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  const benefitsItems = data.contentfulFaqGroup.FAQItems.map(
    (item: { id: string; question: string; answer: { raw: any } }) => ({
      id: item.id,
      value: item.question,
      description: renderRichBodyText({
        raw: item.answer.raw,
        references: null,
      }),
    })
  );

  return (
    <Container>
      <MaxWidthWrapper>
        <HeadingUppercase
          css={css`
            margin-top: 20px;
          `}
        >
          Why membership?
        </HeadingUppercase>
        <Accordion faq={benefitsItems} initialState={null} font='Adonis' />
        <ButtonCTA text={button} href={href} />
      </MaxWidthWrapper>
    </Container>
  );
};

export default Benefits;

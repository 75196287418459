// Function to get the query value for the Founding Member LPs variants ($75 & 50%)

import { useEffect, useState } from 'react';

const getQuery = () => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    const urlQuery = window.location.search;
    const queryValue = new URLSearchParams(urlQuery).get('sdcp');

    if (queryValue?.includes('75') || queryValue?.includes('50')) {
      const splitQuery = queryValue.split('-');
      setQuery(splitQuery[1]);
    }
  }, []);

  return query;
};

export default getQuery;

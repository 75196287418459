import styled from '@emotion/styled';

const HeadingUppercase = styled.h4`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 60px;
`;

export default HeadingUppercase;

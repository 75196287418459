import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { MaxWidthWrapper } from '../../ui/utils';
import Heading3 from '../../ui/Heading3';
import HeadingUppercase from '../../ui/HeadingUppercase';

const Container = styled.section`
  background-color: ${COLORS.NEW_LIGHT_BEIGE};
  padding: 60px 100px;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
    margin-bottom: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const Opening = styled.div`
  max-width: fit-content;

  @media (max-width: 950px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
`;

const OpeningText = styled(Heading3)`
  margin: 0;
  width: fit-content;
`;

const Address = styled.div`
  @media (max-width: 950px) {
    margin-bottom: 40px;
  }
`;

const Hours = styled.div`
  @media (max-width: 950px) {
    margin-bottom: 40px;
  }
`;

const Heading = styled(HeadingUppercase)`
  margin-bottom: 24px;
`;

const Info = styled.p`
  margin: 0;
`;

const ContactLink = styled.a`
  color: ${COLORS.BLACK};
`;

interface Props {
  location: string;
  openingDate: string;
  address1: string;
  address2: string;
  hours1: string;
  hours2: string;
  hours3: string;
  phone: string;
  email: string;
}

const Information = ({
  location,
  openingDate,
  address1,
  address2,
  hours1,
  hours2,
  hours3,
  phone,
  email,
}: Props) => (
  <Container>
    <MaxWidthWrapper>
      <Wrapper>
        <Opening>
          <OpeningText>{location}</OpeningText>
          <OpeningText>{openingDate}</OpeningText>
        </Opening>

        <Address>
          <Heading>Address</Heading>
          <Info>{address1}</Info>
          <Info>{address2}</Info>
        </Address>

        <Hours>
          <Heading>Hours</Heading>
          <Info>{hours1}</Info>
          <Info>{hours2}</Info>
          <Info>{hours3}</Info>
        </Hours>

        <div>
          <Heading>Contact</Heading>
          <ContactLink href={`tel:+1 ${phone}`}>{phone}</ContactLink>
          <br />
          <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
        </div>
      </Wrapper>
    </MaxWidthWrapper>
  </Container>
);

export default Information;

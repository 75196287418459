import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { MaxWidthWrapper } from '../../ui/utils';
import ButtonCTA from './ButtonCTA';
import styled from '@emotion/styled';
import HeadingUppercase from '../../ui/HeadingUppercase';
import GreenCheck from '../../images/icons/CheckCircleFilled.svg';
import ReactMarkdown from 'react-markdown';

const Container = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 100px;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
    margin-bottom: 12px;
  }
`;

const Wrapper = styled(MaxWidthWrapper)`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  max-width: 650px;
  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
  }
`;

const SubHeader = styled(HeadingUppercase)`
  margin-bottom: 24px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 16px;
  }
`;

const Header = styled.h2`
  font-family: Adonis !important;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 32px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 48px;
    margin-bottom: 16px;
  }
`;

const List = styled.ul`
  li {
    list-style-image: url(${GreenCheck});
    margin-bottom: 8px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.15px;
    position: relative;
    top: -5px;
    margin-left: 8px;
  }

  strong {
    font-weight: 600;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;

  @media (max-width: 900px) {
    margin-bottom: 16px;
    width: 100%;
  }
`;

const Pricing = styled.span`
  font-family: Adonis !important;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0.5px;

  @media (max-width: 820px) {
    font-size: 40px;
  }
`;

const DesktopImage = styled.div`
  width: 540px;
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    display: none;
  }
`;

const MobileImage = styled.div`
  display: none;

  @media (max-width: 650px) {
    display: block;
    width: 340px;
    margin: 0 auto;
    margin-top: 40px;
  }
`;

interface Props {
  subheader: string;
  header: string;
  pricing: string;
  button: string;
  href: string;
  queryVariant?: string;
  regularPrice?: string;
  discountPrice50?: string;
  discountPrice75?: string;
  bodyTextVariant?: string;
  perks50?: any;
  perks75?: any;
  perkFree?: string;
  perk75?: string;
  perk50?: string;
  perk2?: string;
  perk3?: string;
  perk4?: string;
}

const Hero = ({
  subheader,
  header,
  pricing,
  button,
  href,
  queryVariant,
  regularPrice,
  discountPrice50,
  discountPrice75,
  bodyTextVariant,
  perkFree,
  perk75,
  perk50,
  perk2,
  perk3,
  perk4,
}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "graphics/FoundingMember_HeroImage.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(
        relativePath: { eq: "graphics/FoundingMember_HeroImage.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <Text>
          <SubHeader>{subheader}</SubHeader>
          <Header>{header}</Header>
          <p style={{ marginBottom: 24 }}>{bodyTextVariant}</p>
          <List>
            {queryVariant === '75' ? (
              <li>
                <ReactMarkdown>{perk75}</ReactMarkdown>
              </li>
            ) : queryVariant === '50' ? (
              <li>
                <ReactMarkdown>{perk50}</ReactMarkdown>
              </li>
            ) : (
              <li>
                <ReactMarkdown>{perkFree}</ReactMarkdown>
              </li>
            )}
            <li>
              <ReactMarkdown>{perk2}</ReactMarkdown>
            </li>
            <li>
              <ReactMarkdown>{perk3}</ReactMarkdown>
            </li>
            <li>
              <ReactMarkdown>{perk4}</ReactMarkdown>
            </li>
          </List>
          <Bottom>
            <CTAWrapper>
              <Pricing
                css={css`
                  text-decoration: line-through;
                  margin-right: 16px;
                `}
              >
                {regularPrice}
              </Pricing>
              {queryVariant === '75' ? (
                <Pricing>{discountPrice75}</Pricing>
              ) : queryVariant === '50' ? (
                <Pricing>{discountPrice50}</Pricing>
              ) : (
                <Pricing>{pricing}</Pricing>
              )}
            </CTAWrapper>
            <ButtonCTA text={button} href={href} />
          </Bottom>
        </Text>
        <DesktopImage>
          <div style={{ width: '100%' }}>
            <Img
              fluid={data.desktop.childImageSharp.fluid}
              alt='Doodle of a man and his dog going through a door with a Welcome sign.'
            />
          </div>
        </DesktopImage>
        <MobileImage>
          <Img
            fluid={data.mobile.childImageSharp.fluid}
            alt='Doodle of a man and his dog going through a door with a Welcome sign.'
          />
        </MobileImage>
      </Wrapper>
    </Container>
  );
};

export default Hero;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Carousel from '../carousel/Carousel';

const PhotoCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "carousel/FM_LocationPage_01.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic2: file(relativePath: { eq: "carousel/FM_LocationPage_02.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic3: file(relativePath: { eq: "carousel/FM_LocationPage_03.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic4: file(relativePath: { eq: "carousel/FM_LocationPage_04.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic5: file(relativePath: { eq: "carousel/FM_LocationPage_05.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic6: file(relativePath: { eq: "carousel/FM_LocationPage_06.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic7: file(relativePath: { eq: "carousel/FM_LocationPage_07.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pic8: file(relativePath: { eq: "carousel/FM_LocationPage_08.webp" }) {
        childImageSharp {
          fixed(height: 640, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <Carousel
      maxHeight='640px'
      images={[
        { fixed: data.pic8.childImageSharp.fixed },
        { fixed: data.pic1.childImageSharp.fixed },
        { fixed: data.pic2.childImageSharp.fixed },
        { fixed: data.pic3.childImageSharp.fixed },
        { fixed: data.pic4.childImageSharp.fixed },
        { fixed: data.pic5.childImageSharp.fixed },
        { fixed: data.pic6.childImageSharp.fixed },
        { fixed: data.pic7.childImageSharp.fixed },
      ]}
    />
  );
};

export default PhotoCarousel;

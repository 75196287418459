import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { COLORS, BREAKPOINTS } from '../../ui/variables';
import Accordion from './Accordion';
import { MaxWidthWrapper } from '../../ui/utils';
import HeadingUppercase from '../../ui/HeadingUppercase';
import renderRichBodyText from '../../utils/renderRichText';

const Container = styled.section`
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  padding: 140px 100px;
  margin-bottom: 20px;
  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
    margin-bottom: 12px;
  }
`;

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFaqGroup(groupName: { eq: "FAQ Founding Member " }) {
        FAQItems {
          ... on ContentfulAccordionItem {
            id
            question
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  const faqItems = data.contentfulFaqGroup.FAQItems.map(
    (item: { id: string; question: string; answer: { raw: any } }) => ({
      id: item.id,
      value: item.question,
      description: renderRichBodyText({
        raw: item.answer.raw,
        references: null,
      }),
    })
  );

  return (
    <Container>
      <MaxWidthWrapper>
        <HeadingUppercase
          css={css`
            margin-top: 20px;
          `}
        >
          Common questions
        </HeadingUppercase>
        <Accordion
          faq={faqItems}
          initialState={null}
          borderColor='white'
          font='GT Walsheim'
        />
      </MaxWidthWrapper>
    </Container>
  );
};

export default FAQ;

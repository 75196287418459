import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import useEmblaCarousel from 'embla-carousel-react';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { ReactComponent as NextIcon } from '../../images/icons/arrow-right-black.svg';
import { BREAKPOINTS, COLORS } from '../../ui/variables';

const Container = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 12px;
  }
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
`;

const Slide = styled.div`
  position: relative;
  padding-left: 10px;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: ${COLORS.DARK_BLUE};
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  transition: all 300ms ease;

  @media ${BREAKPOINTS.TABLET} {
    width: 48px;
    height: 48px;
  }

  svg {
    width: 24px;
    fill: ${COLORS.WHITE};

    @media ${BREAKPOINTS.TABLET} {
      right: 24px;
      width: 19px;
    }
  }

  &:hover {
    background-color: #232c3b;
  }
`;

const NextButton = styled(Button)`
  right: 70px;

  @media ${BREAKPOINTS.TABLET} {
    right: 42px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

const PrevButton = styled(Button)`
  left: 70px;

  svg {
    transform: rotate(180deg);
  }

  @media ${BREAKPOINTS.TABLET} {
    left: 42px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    display: none;
  }
`;

interface Props {
  maxHeight?: string;
  images: {
    fixed: FixedObject;
    fluid?: FluidObject;
    alt?: string;
    position?: 'top' | 'bottom' | 'center';
  }[];
}

const Carousel = (props: Props) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'center',
    startIndex: 1,
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  const slides = props.images.map(image => (
    <Slide key={image.fixed.src}>
      <Img
        style={{
          maxHeight: props.maxHeight || 'none',
        }}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: image.position || 'center',
        }}
        fixed={image.fixed}
      />
    </Slide>
  ));

  return (
    <Container>
      <Viewport ref={viewportRef}>
        <Wrapper>
          {slides}
          {slides}
        </Wrapper>
      </Viewport>
      <PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
        <NextIcon />
      </PrevButton>
      <NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
        <NextIcon />
      </NextButton>
    </Container>
  );
};

export default Carousel;

import { useEffect, useState } from 'react';

interface Props {
  location: string;
  digital?: string;
  variant75?: string;
  variant50?: string;
  events?: string;
  organic?: string;
}

const useUrlQuery = ({
  location,
  digital,
  variant75,
  variant50,
  events,
  organic,
}: Props) => {
  const [urlOnboarding, setUrlOnboarding] = useState('');

  useEffect(() => {
    const urlQuery = window.location.search;
    const queryValue = new URLSearchParams(urlQuery).get('sdcp');

    const checkParam = () => {
      switch (queryValue) {
        case `${location}digital`:
          return setUrlOnboarding(
            `${process.env.GATSBY_NEW_ONBOARDING_URL}signup?foundingmember=${digital}`
          );

        case `${location}-75`:
          return setUrlOnboarding(
            `${process.env.GATSBY_NEW_ONBOARDING_URL}signup?foundingmember=${variant75}`
          );

        case `${location}-50`:
          return setUrlOnboarding(
            `${process.env.GATSBY_NEW_ONBOARDING_URL}signup?foundingmember=${variant50}`
          );

        case `${location}events`:
          return setUrlOnboarding(
            `${process.env.GATSBY_NEW_ONBOARDING_URL}signup?foundingmember=${events}`
          );

        default:
          return setUrlOnboarding(
            `${process.env.GATSBY_NEW_ONBOARDING_URL}signup?foundingmember=${organic}`
          );
      }
    };

    checkParam();
  }, []);

  return urlOnboarding;
};

export default useUrlQuery;

import React from 'react';
import { graphql } from 'gatsby';
import PhotoCarousel from '../../components/founding-member/PhotoCarousel';
import Benefits from '../../components/founding-member/Benefits';
import Testimonials from '../../components/founding-member/Testimonials';
import FAQ from '../../components/founding-member/FAQ';
import FooterCTA from '../../components/founding-member/FooterCTA';
import Information from '../../components/founding-member/Information';
import Hero from '../../components/founding-member/Hero';
import SEO from '../../components/seo';
import loadable from '@loadable/component';
import useUrlQuery from '../../utils/useUrlQuery';
import Header from '../../components/founding-member/Header';
import Footer from '../../components/lp/Footer';
import IndividualPageLayout from '../../components/individualPageLayout';
import getQuery from '../../utils/getQuery';
import { McLeanLandingPageQuery } from '../../../graphql-types';

const Map = loadable(() => import('../../components/founding-member/Map'));

export const pageQuery = graphql`
  query McLeanLandingPage {
    allContentfulPagesFoundingMember(filter: { pageId: { eq: "fm_mclean" } }) {
      nodes {
        seoTitle
        seoDescription
        heroSubHeader
        heroHeader
        heroDescriptionText
        heroPrice
        buttonText
        locationName
        openingDate
        locationLine1
        locationLine2
        hoursLine1
        hoursLine2
        hoursLine3
        contactPhone
        contactEmail
        locationCoordinates {
          lat
          lon
        }
        footerHeader
        footerBodyText {
          footerBodyText
        }
        regularPrice
        discountPrice50
        heroDescriptionTextVariant
        perkFree {
          perkFree
        }
        perk50 {
          perk50
        }
        perk2 {
          perk2
        }
        perk3 {
          perk3
        }
        perk4 {
          perk4
        }
      }
    }
  }
`;

interface Props {
  data: McLeanLandingPageQuery;
}

const McLeanLandingPage = ({ data }: Props) => {
  const {
    seoTitle,
    seoDescription,
    heroSubHeader,
    heroHeader,
    heroPrice,
    buttonText,
    locationName,
    openingDate,
    locationLine1,
    locationLine2,
    hoursLine1,
    hoursLine2,
    hoursLine3,
    contactPhone,
    contactEmail,
    locationCoordinates,
    footerHeader,
    footerBodyText,
    regularPrice,
    discountPrice50,
    heroDescriptionTextVariant,
    perkFree,
    perk50,
    perk2,
    perk3,
    perk4,
  } = data.allContentfulPagesFoundingMember.nodes[0];

  const query = getQuery();

  const url = useUrlQuery({
    location: 'mclean',
    variant50: 'MCLEAN-FM-50',
    organic: 'MCLEAN-FM100',
  });

  return (
    <IndividualPageLayout>
      <Header cta={{ href: url, text: buttonText }} />
      <SEO title={seoTitle} description={seoDescription} />
      <Hero
        subheader={heroSubHeader}
        header={heroHeader}
        pricing={heroPrice}
        button={buttonText}
        href={url}
        queryVariant={query}
        regularPrice={regularPrice}
        discountPrice50={discountPrice50}
        bodyTextVariant={heroDescriptionTextVariant}
        perkFree={perkFree.perkFree}
        perk50={perk50.perk50}
        perk2={perk2.perk2}
        perk3={perk3.perk3}
        perk4={perk4.perk4}
      />
      <Information
        location={locationName}
        openingDate={openingDate}
        address1={locationLine1}
        address2={locationLine2}
        hours1={hoursLine1}
        hours2={hoursLine2}
        hours3={hoursLine3}
        phone={contactPhone}
        email={contactEmail}
      />
      <PhotoCarousel />
      <Benefits button={buttonText} href={url} />
      <Testimonials />
      <Map
        id='location-map'
        lat={locationCoordinates.lat}
        lng={locationCoordinates.lon}
      />
      <FAQ />
      <FooterCTA
        header={footerHeader}
        text={footerBodyText.footerBodyText}
        button={buttonText}
        href={url}
      />
      <Footer />
    </IndividualPageLayout>
  );
};

export default McLeanLandingPage;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { MaxWidthWrapper } from '../../ui/utils';
import ParagraphExLarge from '../../ui/ParagraphExLarge';
import ButtonCTA from './ButtonCTA';

const Container = styled.section`
  background-color: ${COLORS.GREEN};
  padding: 100px 95px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
  }
  @media (max-width: 650px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 900px) {
    width: 50%;
  }
  @media (max-width: 650px) {
    padding: 80px 20px;
    width: 100%;
  }
`;

const Header = styled.h2`
  font-family: Adonis;
  font-size: 60px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -1px;
  max-width: 540px;
  color: ${COLORS.WHITE};

  @media ${BREAKPOINTS.TABLET} {
    font-size: 46px;
    line-height: 58px;
  }
  @media screen and (max-width: 900px) {
    font-size: 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.45px;
  }
`;

const ImageWrapper = styled.div`
  width: 595px;

  @media ${BREAKPOINTS.TABLET} {
    width: 47%;
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

interface Props {
  header: string;
  text: string;
  button: string;
  href: string;
}

const FooterCTA = ({ header, text, button, href }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      garbanzo: file(relativePath: { eq: "garbanzo-cropped.png" }) {
        childImageSharp {
          fluid(maxWidth: 610, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Container>
      <MaxWidthWrapper>
        <Wrapper>
          <Text>
            <Header>{header}</Header>
            <ParagraphExLarge
              css={css`
                color: ${COLORS.WHITE};
                line-height: 1.65;
                letter-spacing: -0.3px;
                margin-bottom: 40px;
                @media ${BREAKPOINTS.MOBILE} {
                  font-size: 18px;
                }
              `}
            >
              {text}
            </ParagraphExLarge>
            <div>
              <ButtonCTA text={button} href={href} />
            </div>
          </Text>
          <ImageWrapper>
            <Img
              fluid={data.garbanzo.childImageSharp.fluid}
              alt='Two woman and a dog waiting in the lounge room.'
            />
          </ImageWrapper>
        </Wrapper>
      </MaxWidthWrapper>
    </Container>
  );
};

export default FooterCTA;
